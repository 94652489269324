// FAQPage.jsx
import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQPage() {
  // Example FAQ data. Customize as needed.
  const faqs = [
    {
      question: 'How do I sign up and log in?',
      answer: `Simply click on the "Sign Up" link or button, fill out your information, and then you can log in using your email and password. Once logged in, you can manage your dealership and generate invoices.`
    },
    {
      question: 'How do I get paid?',
      answer: `Using Stripe Connect, you can link your bank account. Once your account is connected, any payments you receive through CryptAuto will be automatically sent to your bank within 1-2 business days.`
    },
    {
      question: 'Do I need to connect my bank using Stripe Connect?',
      answer: `Yes. This is necessary in order to receive your payouts. During the account link flow, you'll be asked to provide your bank info, which Stripe uses to deposit funds from your crypto invoices.`
    },
    {
      question: 'Can customers pay with Stablecoins, BTC, or ETH?',
      answer: `Currently, we only support stablecoin payments through Stripe. We can only accept crypto that Stripe supports, which are USDC (Ethereum, Solana, or Polygon networks) or USDP (Ethereum, Solana networks).`
    },
    {
      question: 'Is there a fee for each invoice?',
      answer: `Yes. There is a 3% fee to use our service. 1.5% goes directly to Stripe for using their crypto network, 1.5% goes to CryptAuto.`
    },
    {
      question: 'When do I receive my payouts?',
      answer: `Once your customers pay their crypto invoice, Stripe converts the crypto to USD and creates an ACH deposit directly to your bank account. Stripe typically deposits the funds into your connected bank account within 1-2 business days, though timing can vary.`
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: 'auto',
        mt: 4,
        mb: 4,
        p: 2
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Frequently Asked Questions
      </Typography>

      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-content-${index}`}
            id={`faq-header-${index}`}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default FAQPage;