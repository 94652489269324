import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import fetchUser from '../Users/utils';
import AdminHome from '../containers/Admin/AdminHome';
import baseUrl from '../url';

const Home = ({ user, setUser }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [openHowItWorks, setOpenHowItWorks] = useState(false); // <--- State for the modal

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const queryUserId = queryParams.get('userId');
  const needsAdditionalInfo = queryParams.get('needsAdditionalInfo') === 'true';

  useEffect(() => {
    const handleAuthCallback = async () => {
      if (token && queryUserId) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', queryUserId);
        if (needsAdditionalInfo) {
          navigate('/additional-info');
        } else {
          const userData = await fetchUser();
          setUser(userData);
          window.history.replaceState(null, null, '/'); // Clear query parameters
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 500); // Ensures the spinner shows for at least 0.5 seconds
    };
    handleAuthCallback();
  }, [token, queryUserId, needsAdditionalInfo, navigate, setUser]);

  useEffect(() => {
    const getUser = async () => {
      if (!token && !queryUserId) {
        setLoading(true);
        const userData = await fetchUser();
        setUser(userData);
        setTimeout(() => {
          setLoading(false);
        }, 500); // Ensures the spinner shows for at least 0.5 seconds
      }
    };
    getUser();
  }, [setUser, token, queryUserId]);

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  // Handlers for How It Works dialog
  const handleOpenHowItWorks = () => setOpenHowItWorks(true);
  const handleCloseHowItWorks = () => setOpenHowItWorks(false);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start', // Adjusts the position to the top
        height: '100vh',
        backgroundColor: 'background.default',
        pt: 8, // Adds padding to the top
      }}
    >
      <Box sx={{ mb: 4 }}>
        <img
          src="/cryptauto_banner_transparent.png"
          alt="CryptAuto Banner"
          style={{ width: '100%', maxWidth: '600px', height: 'auto' }}
        />
      </Box>

      {user?.is_admin ? (
        <AdminHome user={user} />
      ) : user && user.dealership ? (
        <Paper
          elevation={3}
          sx={{ p: 4, textAlign: 'center', backgroundColor: 'grey.900' }}
        >
          <Typography variant="h4" gutterBottom>
            Welcome back, {user.dealership.name}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              component={Link}
              to={`/dealerships/${user.dealership.name.replace(/\s+/g, '-')}`}
              variant="contained"
              color="primary"
              size="large"
            >
              My Dealership
            </Button>
          </Box>
        </Paper>
      ) : (
        <>
          <Typography variant="h4" gutterBottom>
            Welcome to CryptAuto
          </Typography>
          <Button
            component={Link}
            to="/login"
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
            onClick={() => setLoading(true)} // Ensure spinner shows immediately
          >
            Login
          </Button>

          {/* "How does it work" button */}
          <Button
            variant="text"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleOpenHowItWorks}
          >
            How does it work?
          </Button>

          {/* Dialog for "How does it work?" */}
          <Dialog open={openHowItWorks} onClose={handleCloseHowItWorks}>
            <DialogTitle>Getting Started with CryptAuto</DialogTitle>
            <DialogContent dividers>
              <DialogContentText component="div" sx={{ whiteSpace: 'pre-line' }}>
                - Sign up and log in
                <br />
                - Add a bank where you would like to receive payouts out via Stripe
                <br />
                - Generate invoices for your customers to easily pay with crypto
                <br />
                - Automatically receive payment directly into your bank account
                  within 1-2 business days
              </DialogContentText>

              <DialogContentText component="div" sx={{ whiteSpace: 'pre-line', mt: 2 }}>
                If you have more questions, please check out our{' '}
                <Link to="/faqs" style={{ color: 'inherit', textDecoration: 'underline' }}>
                  FAQs
                </Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseHowItWorks} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default Home;