import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Paper, Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StripePaymentModal from '../components/PaymentModals/StripePaymentModal';
import baseUrl from '../url';
import PaymentsTable from './PaymentsTable';
import fetchUser from '../Users/utils';

const DealershipDetail = () => {
  const { name } = useParams();
  const [dealership, setDealership] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [user, setUser] = useState(null);
  console.log("user", user);

  // For Stripe Connect account link creation
  const [accountLinkLoading, setAccountLinkLoading] = useState(false);

  // For Plaid
  // const [linkToken, setLinkToken] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dealershipResponse, userData] = await Promise.all([
          axios.get(`${baseUrl}/api/dealerships/${name}`),
          fetchUser(),
        ]);
        setDealership(dealershipResponse.data);
        setUser(userData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch dealership or user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name]);

  const fetchPaymentsData = async () => {
    if (dealership) {
      try {
        const response = await axios.get(`${baseUrl}/api/payments/${dealership.id}`);
        setPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    }
  };

  useEffect(() => {
    fetchPaymentsData();
  }, [dealership]);

  // Create Plaid Link token when dealership is loaded
  // useEffect(() => {
  //   const createLinkToken = async () => {
  //     if (dealership) {
  //       try {
  //         const response = await axios.post(`${baseUrl}/api/plaid/link`, { dealershipId: dealership.id });
  //         setLinkToken(response.data.link_token);
  //       } catch (error) {
  //         console.error('Error generating Plaid link token:', error);
  //       }
  //     }
  //   };
  //   createLinkToken();
  // }, [dealership]);

  // Plaid Link hook
  // const { open: openPlaid, ready: plaidReady } = usePlaidLink({
  //   token: linkToken,
  //   onSuccess: async (publicToken, metadata) => {
  //     try {
  //       await axios.post(`${baseUrl}/api/plaid/exchange`, { publicToken, dealershipId: dealership.id });
  //       toast.success('Bank account connected via Plaid!');
  //     } catch (error) {
  //       console.error('Error saving ACH details:', error);
  //       toast.error('Error saving ACH details. Please try again.');
  //     }
  //   },
  //   onExit: (error, metadata) => {
  //     if (error) {
  //       console.error('Plaid Link exited with error:', error);
  //       toast.error('Plaid Link exited with an error.');
  //     }
  //   },
  // });

  const handleConnectWithStripe = async () => {
    if (!user) return;
    try {
      setAccountLinkLoading(true);
      const response = await axios.get(`${baseUrl}/api/stripe/create-account-link/${user.id}`);
      console.log('Stripe account link:', response.data);
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      console.log("error")
      console.error('Error creating account link:', error);
      toast.error('Error creating Stripe account link. Please try again.');
    } finally {
      setAccountLinkLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dealership) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Dealership not found.</Typography>
      </Box>
    );
  }

  const isStripeConnected = user?.stripe_connected;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
      <Paper
        sx={{
          p: 4,
          maxWidth: 600,
          width: '100%',
          textAlign: 'center',
          backgroundColor: 'grey.900',
          color: 'white',
          mb: 4
        }}
      >
        <Typography variant="h4" gutterBottom>
          {dealership.name}
        </Typography>
        {!isStripeConnected && (
          <Typography variant="body1" color="primary">
            Please onboard your payout bank through Stripe in order to start creating invoices.
          </Typography>
        )}
        {/* Render both payment modals side-by-side */}
        <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
          {/* <CoinbasePaymentModal
            dealership={dealership}
            fetchPayments={fetchPaymentsData}
          /> */}

          <StripePaymentModal
            dealership={dealership}
            fetchPayments={fetchPaymentsData}
            userId={user?.id}
            stripeConnected={isStripeConnected}
          />
        </Box>

        {/* Connect Bank with Plaid Button */}
        {/* {!isPlaidConnected && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2}}
            onClick={() => openPlaid()}
            disabled={!plaidReady}
          >
            Connect Bank with Plaid
          </Button>
        )} */}


        {/* Connect Bank with Stripe Button */}
        {!isStripeConnected && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2, ml: 2 }}
            onClick={handleConnectWithStripe}
            disabled={accountLinkLoading}
          >
            {accountLinkLoading ? 'Loading...' : 'Connect Bank with Stripe'}
          </Button>
        )}

      </Paper>

      <PaymentsTable payments={payments} />
      <ToastContainer />
    </Box>
  );
};

export default DealershipDetail;